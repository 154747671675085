import { useMemo } from "react";
import { usePunditUserContext } from "@circle-react/contexts";
import { useForceV3Context } from "@circle-react/contexts/ForceV3";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { useLocalStorageState } from "@circle-react/hooks/utils/useLocalStorageState";

export interface UseIsV3Props {
  iconSize: number;
  isCircleV3Eligible: boolean;
  isCircleV3Preview: boolean;
  isCurrentMemberAdmin: boolean;
  isPreviewEnabledForAdmin: boolean;
  isV3DisabledFromParams: boolean;
  isV3Enabled: boolean;
  isV3EnabledFromFeatureFlag: boolean;
  isV3EnabledFromLocalStorage?: boolean;
  isV3EnabledFromParams: boolean;
  isV3Forced: boolean;
  shouldShowPostModal: boolean;
}

export const useIsV3 = (): UseIsV3Props => {
  const isV3Forced = useForceV3Context();
  const { search, pathname } = location;
  const params = new URLSearchParams(search);
  const [isV3EnabledFromLocalStorage, setIsV3EnabledFromLocalStorage] =
    useLocalStorageState<boolean | undefined>("v3_ui");

  const { currentCommunitySettings, currentCommunityMember } =
    usePunditUserContext();

  const isCurrentMemberAdmin = isCommunityAdmin(currentCommunityMember);

  const isV3EnabledFromFeatureFlag = Boolean(
    currentCommunitySettings?.circle_v3_enabled,
  );
  const isCircleV3Preview = Boolean(
    currentCommunitySettings?.circle_v3_preview,
  );
  const isCircleV3Eligible = Boolean(
    currentCommunitySettings?.circle_v3_eligible,
  );

  const isV3EnabledFromParams = params.get("v3_ui") === "true";
  const isV3DisabledFromParams = params.get("v3_ui") === "false";

  const isPreviewEnabledForAdmin = isCircleV3Preview && isCurrentMemberAdmin;

  const isV3Enabled = useMemo(() => {
    if (isV3EnabledFromFeatureFlag || isPreviewEnabledForAdmin || isV3Forced) {
      return true;
    }

    if (isV3EnabledFromParams) {
      setIsV3EnabledFromLocalStorage(true);
      return true;
    }

    if (isV3DisabledFromParams) {
      setIsV3EnabledFromLocalStorage(false);
      return false;
    }

    if (isV3EnabledFromLocalStorage !== undefined) {
      return Boolean(isV3EnabledFromLocalStorage);
    }

    return false;
  }, [
    isPreviewEnabledForAdmin,
    isV3DisabledFromParams,
    isV3EnabledFromFeatureFlag,
    isV3EnabledFromLocalStorage,
    isV3EnabledFromParams,
    isV3Forced,
    setIsV3EnabledFromLocalStorage,
  ]);

  /*
   * The Checkout component is mounted independently via checkout/show.html.erb
   * using the react_mount_controller.js. Since it is not part of the MainApp, it
   * does not include the BrowserRouter provider from react-router-dom. As a result,
   * the useRouteMatch() method fails because the router context is missing. And hence,
   * using useRouteMatch() for `isPostDetails` fails in the checkout page scenario, and hence
   * we are using a REGEX based pattern matching for the post details page route.
   */
  const isPostDetailsPage = /^\/c\/[^/]+\/[^/]+\/?$/.test(pathname);
  const shouldShowPostModal = isV3Enabled && !isPostDetailsPage;

  const iconSize = isV3Enabled ? 20 : 16;

  return {
    iconSize,
    isCircleV3Eligible,
    isCircleV3Preview,
    isCurrentMemberAdmin,
    isPreviewEnabledForAdmin,
    isV3DisabledFromParams,
    isV3Enabled,
    isV3EnabledFromFeatureFlag,
    isV3EnabledFromLocalStorage,
    isV3EnabledFromParams,
    isV3Forced,
    shouldShowPostModal,
  };
};
